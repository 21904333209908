import { ReactElement, memo } from 'react';
import { TImageItem } from '@/common/service/api/Activity/Activity.domain';
import { ImagesGroup } from '@/common/ui/images/ImagesGroup/ImagesGroup';
import { IVideo } from '@/entities/Activity/domain/Activity.domain';
import { createAssets } from '@/screens/ActivityPage/service/createAssets';
import Video from '@/common/ui/images/Video/Video';
import { ImagesInfinityGallery } from '@/shared/ImagesGallery/ImagesGallery';
import { ImgWrapper } from './ImagesGallery.styles';

type ImagesGalleryProps = {
    userImages: TImageItem[];
    activityId: string;
    activitySlug: string;
    videos: IVideo[];
    totalPurchased?: string;
    recentPurchased?: string;
    type?: 'photo' | 'landmark_photo';
    startPosition?: number;
    eagerMore?: boolean;
};

export const ImagesGalleryContainer = ({
    userImages,
    activityId,
    activitySlug,
    videos,
    totalPurchased,
    recentPurchased,
    type,
    startPosition,
    eagerMore,
}: ImagesGalleryProps): ReactElement => {
    const assets = createAssets({ userImages, activityId, activitySlug, videos, eagerMore, type });
    const currentAssets =
        assets.length > 1 ? [assets[assets.length - 1], ...assets, assets[0]] : assets;

    const itemsElement = (page?: number) =>
        currentAssets.map((item, index) => {
            const alsoEager =
                !!page && (index === page - 1 || index === page + 1) && !item.value.eager;

            if (item.type === 'video') {
                return <Video link={item.value.slug} key={item.value.id + index} />;
            }

            return (
                <ImgWrapper key={item.value.id + index}>
                    <ImagesGroup
                        linkKey={item.type}
                        id={item.value.id}
                        slug={item.value.slug}
                        type="activity"
                        eager={item.value.eager || alsoEager}
                    />
                </ImgWrapper>
            );
        });

    return (
        <ImagesInfinityGallery
            items={currentAssets}
            itemsElement={itemsElement}
            totalPurchased={totalPurchased}
            recentPurchased={recentPurchased}
            startPosition={startPosition}
        />
    );
};

export const ImagesGallery = memo(ImagesGalleryContainer);
