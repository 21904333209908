import styled from 'styled-components';
import { radius } from '@/styles/Themes/Metrics';
import { IImageTag } from './ImagesGroup.types';

export const Image = styled.picture`
    border-radius: ${radius.small};
    min-height: 100%;
`;

export const ImageTag = styled.img<IImageTag>`
    width: ${({ isNotActivityPage }) => (isNotActivityPage ? '' : '100%')};
    height: 100%;
    object-fit: cover; // это не работает, а надо
    pointer-events: none;
    display: block;
    min-width: 100%;
    min-height: 100%;
`;
