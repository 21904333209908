import { ReactElement, memo, useId } from 'react';
import * as S from './DotsBlock.styles';
import { SliderDots } from '../SliderDots/SliderDots.styles';
import { TMoveEvent } from '@/shared/ImagesGallery/ImagesGallery';

type DotsBlockProps = {
    dotsCount: number;
    activeDot: number;
    cb: (event: TMoveEvent, index: number) => void;
};

const HALF_OF_VIEWPORT = 7;

export const DotsBlockContainer = ({ dotsCount, activeDot, cb }: DotsBlockProps): ReactElement => {
    const id = useId();

    const restDots = dotsCount - activeDot - 1;
    const dotsAfter = restDots <= HALF_OF_VIEWPORT ? HALF_OF_VIEWPORT - restDots : 0;
    const dotsBefore = activeDot > HALF_OF_VIEWPORT ? activeDot - HALF_OF_VIEWPORT : 0;
    const offset = dotsBefore - dotsAfter;

    return (
        <S.DotsContainer>
            <S.SizeContainer>
                <S.DotsBlock offset={offset}>
                    {Array(dotsCount)
                        .fill(0)
                        .map((_, index) => {
                            return (
                                <SliderDots
                                    isActive={index === activeDot}
                                    key={id + index}
                                    onClick={(event) => cb(event, index + 1)}
                                    size={8}
                                    desktop
                                />
                            );
                        })}
                </S.DotsBlock>
            </S.SizeContainer>
        </S.DotsContainer>
    );
};

export const DotsBlock = memo(DotsBlockContainer);
