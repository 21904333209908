import { YoutubeBtn } from '@/shared/images/icons/YoutubeBtn';
import styled, { css } from 'styled-components';
import Screen from '@/styles/Themes/Screen';

export const VideoWrapper = styled.div<{ smallBtn?: boolean }>`
    position: relative;
    ${({ smallBtn }) => smallBtn && 'height: 100%;'}
    min-width: 100%;
    width: 100%;
    > iframe {
        overflow: hidden;
        border: 0;
        align-self: center;
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    height: 280px;

    ${Screen.ipad} {
        height: 350px;
    }
    ${Screen.desktop} {
        height: 480px;
    }
`;

export const VideoPreview = styled.div<{ imageSrc: string }>`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
`;

export const VideoImg = styled.img`
    object-fit: cover;
`;

export const PlayBtn = styled(YoutubeBtn)<{ smallBtn?: boolean }>`
    position: absolute;
    z-index: 3;

    width: 68px;
    height: 48px;

    ${({ smallBtn }) =>
        smallBtn &&
        css`
            width: 34px;
            height: 24px;

            ${Screen.desktop} {
                width: 68px;
                height: 48px;
            }
        `}
`;
