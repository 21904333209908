import { TImageItem } from '@/common/service/api/Activity/Activity.domain';
import { IVideo } from '@/entities/Activity/domain/Activity.domain';

export type TAssets = {
    type: 'activity' | 'photo' | 'video' | 'landmark_photo';
    value: {
        id: string;
        slug: string;
        eager?: boolean;
    };
};

export type TNewAssets = {
    type: 'activity' | 'photo' | 'video' | 'landmark_photo';
    id: string;
    placeholder: string;
};

type TUseAssetsProps = {
    userImages: TImageItem[];
    activityId: string;
    activitySlug: string;
    videos: IVideo[];
    type?: 'photo' | 'landmark_photo';
    eagerMore?: boolean;
};

export const createAssets = ({
    userImages,
    activityId,
    activitySlug,
    videos,
    eagerMore,
    type,
}: TUseAssetsProps): TAssets[] => {
    const isLandmark = type === 'landmark_photo';

    const activityAssets: TAssets[] = isLandmark
        ? []
        : [
              {
                  type: 'activity',
                  value: {
                      id: activityId,
                      slug: activitySlug,
                      eager: true,
                  },
              },
          ];

    const videoAssets: TAssets[] = videos.map((video, index) => ({
        type: 'video',
        value: {
            id: `video_${index}`,
            slug: video.link,
        },
    }));

    const photoAssets: TAssets[] = userImages.map(({ id, slug }, idx) => ({
        type: type || 'photo',
        value: {
            id,
            slug: activitySlug || slug,
            eager: (eagerMore && idx < 2) || idx === userImages.length - 1,
        },
    }));

    const entries = [...videoAssets].concat(photoAssets);
    const lastChild = entries[entries.length - 1];

    return [...activityAssets, ...entries.slice(0, entries.length - 1), lastChild].filter(Boolean);
};
