import styled from 'styled-components';

const DOT_SIZE = 18;

export const DotsBlock = styled.div<{ offset: number }>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: fit-content;
    ${({ offset }) => offset && `translate: -${offset * DOT_SIZE}px;`}
`;

export const DotsContainer = styled.div`
    position: absolute;
    bottom: 30px;
    z-index: 9;
    width: 100%;
    display: flex;
    justify-content: center;
`;

export const SizeContainer = styled.div`
    max-width: 274px;
    height: 30px;
    overflow: hidden;
`;
