import { ReactElement, memo } from 'react';
import * as S from './ImagesGroup.styles';
import URL from '@/common/app/utils/urlsUtils';
import { TImagesSizesType } from './ImagesSizes';
import emptyImg from '@/shared/images/Images/EmptyImg.png';
import { useRouter } from 'next/router';

type ImagesGroupProps = {
    linkKey: 'activity' | 'photo' | 'video' | 'landmark_photo';
    id: string;
    slug: string;
    type: TImagesSizesType;
    eager?: boolean;
};

export const ImagesGroupContainer = ({
    linkKey,
    id,
    slug,
    eager,
}: ImagesGroupProps): ReactElement => {
    const router = useRouter();
    const currentUrl = router.asPath;
    const isNotActivityPage = currentUrl.includes('/destination/');

    return (
        <S.Image>
            {/* {!isNoImg &&
                imgSizes.map(({ media, size }) => {
                    return (
                        <>
                            <source
                                type="image/webp"
                                media={media}
                                srcSet={`${URL.image}/${linkKey}/${id}/${size}/${slug}.webp`}
                                key={id + size}
                            />
                        </>
                    );
                })}
            <source type="image/webp" srcSet={`${URL.image}/${linkKey}/${id}/${slug}.webp`} /> */}
            <S.ImageTag
                src={`${URL.image}/${linkKey}/${id}/${slug}.jpg`}
                loading={eager ? 'eager' : 'lazy'}
                alt={slug}
                onError={({ currentTarget }) => {
                    //setIsNoImg(true);
                    currentTarget.onerror = null;
                    currentTarget.src = `${emptyImg.src}`;
                }}
                isNotActivityPage={isNotActivityPage}
                style={{ objectFit: 'cover' }} // это не работает, а надо
            />
        </S.Image>
    );
};

export const ImagesGroup = memo(ImagesGroupContainer);
