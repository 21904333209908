import styled from 'styled-components';
import Screen from '@/styles/Themes/Screen';
import { ArrowLeftCard } from '@/shared/images/icons/ArrowLeftCard';
import { radius } from '@/styles/Themes/Metrics';

export const Container = styled.div`
    position: relative;
    overflow: hidden;
    border-radius: ${radius.small};

    ${Screen.desktop} {
        margin-right: 18px;
    }
`;

export const CenterRightArrow = styled(ArrowLeftCard)`
    position: absolute;
    transform: rotate(180deg);
    height: 30px;
    width: 30px;
    right: 5px;
    top: 45%;
    cursor: pointer;

    ${Screen.ipad} {
        height: 36px;
        width: 36px;
    }
`;

export const CenterLeftArrow = styled(ArrowLeftCard)`
    position: absolute;
    height: 30px;
    width: 30px;
    left: 5px;
    top: 45%;
    cursor: pointer;

    ${Screen.ipad} {
        height: 36px;
        width: 36px;
    }
`;

export const Gradient = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: ${radius.small};
    background-image: linear-gradient(
        180deg,
        rgba(20, 19, 19, 0) 31.6%,
        rgba(0, 0, 0, 0.052) 73.45%
    );
`;

export const GalleryWrapper = styled.div<{ page: number }>`
    transform: ${(props) => `translateX(-${props.page}00%)`};
    transition: 0.5s ease 0s;
    width: auto;
    display: flex;
    user-select: none;
`;

export const ImgWrapper = styled.div`
    min-width: 100%;
    overflow: auto;
    height: 280px;

    ${Screen.ipad} {
        height: 350px;
    }
    ${Screen.desktop} {
        height: 480px;
    }
`;
