import { ReactElement, useState } from 'react';
import * as S from './Video.styles';
import {
    createYoutubeEmbedPreviewSrc,
    createYoutubeLink,
} from '@/common/app/utils/createYoutubeLink';
import { useInView } from 'react-intersection-observer';

type videoProps = {
    link: string;
    preventPlay?: boolean;
    smallBtn?: boolean;
};

const Video = ({ link, preventPlay, smallBtn }: videoProps): ReactElement => {
    const [playVideo, setPlayVideo] = useState(false);
    const [ref] = useInView({
        threshold: 0.1,
        onChange: (inView) => {
            if (playVideo && !inView) {
                setPlayVideo(false);
            }
        },
    });

    const newLink = createYoutubeLink(link);
    const previewSrc = createYoutubeEmbedPreviewSrc(newLink);

    return (
        <S.VideoWrapper
            onClick={() => {
                !preventPlay && setPlayVideo(true);
            }}
            ref={ref}
            smallBtn={smallBtn}
        >
            {playVideo ? (
                <iframe src={newLink + '?autoplay=1'} id="youtube_frame" allow="autoplay" />
            ) : (
                <S.VideoPreview imageSrc={previewSrc}>
                    <S.VideoImg src={previewSrc} width="100%" height="100%" alt="video-preview" />
                    <S.PlayBtn smallBtn={smallBtn} />
                </S.VideoPreview>
            )}
        </S.VideoWrapper>
    );
};

export { Video };
export default Video;
